import React from 'react';
import "./loader.scss"

const Loader: React.FC = () => {
  return (
    <div className='loader'>
      <div className='loading' />
    </div>
  );
};

export default Loader;
