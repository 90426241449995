import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IProject } from "../helpers/types";
import "../assets/styles/project.scss";
import Loader from "../components/loader";
import Asset from "../components/asset";
import Layout from "../components/layout";
import Video from "../components/video";

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Project: React.FC = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState<IProject | null>(null);

  useEffect(() => {
    const fetchProjectData = async () => {
      const response = await fetch(
        `https://api.storyblok.com/v2/cdn/stories/projects/${projectId}?token=xI30e5xEzQngQo3VmuqE2wtt&version=draft&cv=2`
      );
      const data = await response.json();
      setProject(data.story);
    };

    fetchProjectData();
  }, [projectId]);

  if (!project) {
    return <Loader />;
  }

  return (
    <Layout>
      <div className="project">
        {project.content.media[0].url ? (
          <Video url={project.content.media[0].url} />
        ) : (
          project.content.media.length > 0 && (
            <Asset
              src={project.content.media[0].media?.filename}
              alt={project.content.media[0].media?.alt}
              title={project.content.media[0].media?.title}
            />
          )
        )}

        <header className="project__header">
          <h1>{project.content.name}</h1>
          <p>{project.content.copy}</p>
        </header>
        <main className="project__main">
          {project.content.media
            .slice(1)
            .map((media) =>
              media.url ? (
                <Video url={media.url} />
              ) : (
                <Asset
                  key={media.media?.id}
                  src={media.media?.filename}
                  alt={media.media?.alt}
                  title={media.media?.title}
                />
              )
            )}
        </main>
      </div>
    </Layout>
  );
};

export default Project;
