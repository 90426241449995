import React, { useState } from "react";
import "./video.scss";
import Loader from "../loader";

interface IVideo {
  url?: string;
}

const Video: React.FC<IVideo> = ({ url }) => {
  const [loading, setLoading] = useState(true);

  const videoSrc = `${process.env.PUBLIC_URL}/videos/${url}.mp4`;

  const handleLoadedData = () => {
    setLoading(false);
  };

  return (
    <div className="video">
      {loading && <Loader />}
      <video autoPlay={true} muted loop playsInline onLoadedData={handleLoadedData}>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
};

export default Video;
