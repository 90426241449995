import React from "react";
import "./footer.scss";

interface IFooter {
  email?: string;
  email_image?: {
    filename: string;
    alt: string;
    title: string;
  };
  social?: string;
}

const Footer: React.FC<IFooter> = ({ email, email_image, social }) => {
  return (
    <footer className="footer">
      <a className="footer_email" href={`mailto:${email}`}>
        {email_image && (
          <img
            src={email_image.filename}
            alt={email_image.filename}
            title={email_image.filename}
          />
        )}
      </a>
      {social && (

        <a className="footer_ig" href={`https://www.instagram.com/${social}`}>
          {social}
        </a>
      )}
    </footer>
  );
};

export default Footer;
