import React, { useState } from "react";
import "./asset.scss";
import Loader from "../loader";

interface IAsset {
  src?: string;
  alt?: string;
  title?: string;
}

const Asset: React.FC<IAsset> = ({ src, alt, title }) => {
  const [loaded, setLoaded] = useState<Boolean>(false);

  return (
    <div className="asset">
        {!loaded && <Loader />}
        <img
          loading="lazy"
          src={src}
          alt={alt}
          title={title}
          onLoad={() => setLoaded(true)}
        />
    </div>
  );
};

export default Asset;
