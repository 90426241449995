import React from 'react';
import "./header.scss"

interface IHeader {
  logo?: string,
  contact?: string
}

const Header: React.FC<IHeader> = ({logo, contact}) => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <header className='header'>
        <a className="header__logo" href="/">{logo}</a>
        <nav>
            <div className='header__contact' onClick={scrollToBottom}>{contact}</div>
        </nav>
    </header>
  );
};

export default Header;
