import { useRouteError } from "react-router-dom";
import Header from "./components/header";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div className="container">
      <Header />
      <div className="hero">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>

      </div>
    </div>
  );
}