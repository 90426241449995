import React, { useEffect, useState } from "react";
import { IProject } from "../helpers/types";
import Loader from "../components/loader";
import Asset from "../components/asset";
import Layout from "../components/layout";

export default function Root() {
  const [projects, setProjects] = useState<IProject[] | null>(null);
  const [hoveredProject, setHoveredProject] = useState<string | null>(null);

  useEffect(() => {
    const fetchProjectsData = async () => {
      const response = await fetch(
        `https://api.storyblok.com/v2/cdn/stories?starts_with=projects&token=xI30e5xEzQngQo3VmuqE2wtt&version=draft&cv=2&sort_by=position`
      );
      const data = await response.json();
      setProjects(data.stories);
      if (data.stories && data.stories.length > 0) {
        setHoveredProject(data.stories[0].slug); // Set the default hovered project as the first one
      }
    };
    fetchProjectsData();
  }, []);

  console.log({ projects });

  if (!projects) {
    return <Loader />;
  }

  return (
    <Layout hero={true}>
      <div className="projects-showcase">
        <div className="projects-list">
          {projects.map((project) => (
            <a
              key={project.slug}
              className="projects-list__item"
              onMouseEnter={() => setHoveredProject(project.slug)}
              href={project.full_slug}
            >
              {project.content.media.length > 0 && (
                <Asset
                  src={
                    project.content.media.find((item) => item.media?.filename)
                      ?.media?.filename
                  }
                  alt={
                    project.content.media.find((item) => item.media?.filename)
                      ?.media?.alt
                  }
                  title={
                    project.content.media.find((item) => item.media?.filename)
                      ?.media?.title
                  }
                />
              )}

              <span>{project.name}</span>
            </a>
          ))}
        </div>
        <div className="projects-images">
          {projects.map((project) => (
            <div
              key={project.slug}
              className={
                "projects-images__item" +
                (hoveredProject === project.slug
                  ? " projects-images__item--active"
                  : "")
              }
            >
              {project.content.media.length > 0 && (
                <Asset
                  src={
                    project.content.media.find((item) => item.media?.filename)
                      ?.media?.filename
                  }
                  alt={
                    project.content.media.find((item) => item.media?.filename)
                      ?.media?.alt
                  }
                  title={
                    project.content.media.find((item) => item.media?.filename)
                      ?.media?.title
                  }
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
