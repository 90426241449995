import React, { useEffect, useState } from "react";
import "./layout.scss";
import Header from "../header";
import Footer from "../footer";

interface ILayout {
  hero?: boolean;
  children: React.ReactNode;
}

interface IHomeData {
  content: {
    footer_email: string;
    footer_email_img: {
      filename: string;
      title: string;
      alt: string;
    };
    footer_social: string;
    header_contact: string;
    header_logo: string;
    hero: any;
  };
}

const Layout: React.FC<ILayout> = ({ hero, children }) => {
  const [homeData, setHomeData] = useState<IHomeData | null>(null);

  useEffect(() => {
    const fetchHomeData = async () => {
      const response = await fetch(
        `https://api.storyblok.com/v2/cdn/stories?starts_with=home&token=xI30e5xEzQngQo3VmuqE2wtt&version=draft&cv=2`
      );
      const data = await response.json();
      setHomeData(data.stories[0]);
    };
    fetchHomeData();
  }, []);

  console.log(homeData?.content.hero);

  return (
    <div className="container">
      <Header
        logo={homeData?.content.header_logo}
        contact={homeData?.content.header_contact}
      />
      {hero && homeData?.content.hero && (
        <h1
          className="hero"
          dangerouslySetInnerHTML={{
            __html: homeData.content.hero.replace(/\*(.*?)\*/g, "<i>$1</i>"),
          }}
        />
      )}
      {children}
      <Footer
        email_image={homeData?.content.footer_email_img}
        email={homeData?.content.footer_email}
        social={homeData?.content.footer_social}
      />
    </div>
  );
};

export default Layout;
